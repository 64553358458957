import './App.css';
import myimg from './img/me.PNG';
import hkmt from './img/HongKongMassage.PNG';
import React, { useRef } from 'react';
import { Typewriter, Cursor } from 'react-simple-typewriter';
import { DiWordpress } from "react-icons/di";
import { FaShopify, FaWix } from "react-icons/fa6";
import emailjs from '@emailjs/browser';

function App() {
  const REACT_APP_PUBLIC_KEY = process.env.REACT_APP_PUBLIC_KEY;
  const REACT_APP_SERVICE_ID = process.env.REACT_APP_SERVICE_ID;
  const REACT_APP_TEMPLATE_ID = process.env.REACT_APP_TEMPLATE_ID;
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();
    emailjs.sendForm(REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, form.current, REACT_APP_PUBLIC_KEY)
      .then(() => {
        document.getElementById("submitbutton").value = "Message Sent";
        document.getElementById("contact-form").reset();
      }, (error) => {
        document.getElementById("submitbutton").value = "Message Did Not Send"
        console.log(error.text);
      });



  };

  return (
    <div id='App'>
      <nav>
          <ul id='navbar'>
            <li><a href="#home">Home</a></li>
            <li><a href="#myabout">About</a></li>
            <li><a href="#services">Services</a></li>
            <li><a href="#portfolio">Portfolio</a></li>
            <li><a href="#contactme">Contact</a></li>
          </ul>
      </nav>

      <div id="home">
        <p id='hellointro'>Professional, Affordable, High Quality</p>
        <h1 id='hellotitle'>
          Website Development
        </h1>
        <div id='frontbuttons'>
          <a href="#contactme"><button type="button">Contact</button></a>
          <a href="#services"><button type="button">Learn More</button></a>
        </div>
      </div>
      
      <div id='myabout'>
        <div id='aboutbg'>
          <h2 id='myaboutme'>About Me</h2>
          <div id='mypicture'>
            <img src={myimg} alt="Selfie" />
          </div>
          <h3 id='skillsinclude'>
          Skills Include: &nbsp;
                <Typewriter
                  words={['HTML', 'CSS', 'JavaScript', 'Databases', 'Web Design', 'Web Development', 'TCP/IP Networking', 'Network Security', 'IT Support']}
                  typeSpeed={90}
                  loop={true} />
                <Cursor />
          </h3>

          
          <p id='aboutparagraph'>
            Recent college graduate for Computer Programming with Honours from Algonquin College. Previous college graduate for Network Systems from Mohawk College. My interest for computers started at a young age, as of 13 I was able to deploy my first MyBB forum on a shared hosting plan. Shortly after, I began helping family with updating their websites and quickly picked up my first development language of HTML and CSS. I am eager to learn new skills, and I am passionate about exploring new technologies. I enjoy taking on challenging projects to push the boundaries of my abilities. From concept to deployment, my focus is on delivering exceptional results while following best practices.
          </p>
        </div>
      </div>

      <div id='services'>
        <div id='servicesbg'>
          <h2 id='servicehead'>
            Services
          </h2>
          <h3 className='servicetitle'>
            Web Development Packages:
          </h3>
          <div className='card-container-one'>
            <div className='large-card'>
              <ul>
                <li><span className='bronzetitle'>Bronze:</span> 500$</li>
                <li>Create a website using web content management system such as WordPress or WiX.</li>
                <li>Three pages (Such as Home, About, Contact)</li>
                <li>Responsive free theme</li>
                <li className='cardicons'><DiWordpress /> <FaWix /></li>
              </ul>
            </div>
            <div className='large-card'>
              <ul>
                <li><span className='silvertitle'>Silver:</span> 750$</li>
                <li>Create a website using web content management system such as WordPress, Shopify or WiX.</li>
                <li>Four pages (Such as Home, About, Products, Contact)</li>
                <li>Responsive free theme</li>
                <li>Additional functionality such as gallery, order system, videos</li>
                <li>Includes one month of free web hosting</li>
                <li className='cardicons'><DiWordpress /> <FaShopify /> <FaWix /></li>
              </ul>
            </div>
            <div className='large-card'>
              <ul>
                <li><span className='goldtitle'>Gold:</span> 1000$</li>
                <li>Create a website using web content management system such as WordPress, Shopify or WiX.</li>
                <li>Five or more pages (Such as Home, About, Services, Contact, Login)</li>
                <li>Responsive free theme </li>
                <li>Additional functionality such as login system, gallery, order system, videos</li>
                <li>SEO Optimization</li>
                <li>Includes one month of free web hosting</li>
                <li className='cardicons'><DiWordpress /> <FaShopify /> <FaWix /></li>
              </ul>
            </div>
          </div>
          <p id='customtext'>* Custom: Please contact to discuss further!</p>

          <h3 className='servicetitle'>Hosting Packages:</h3>

          <div className='card-container-two'>
            <div className='small-card'>
              <ul>
                <li><span className='bronzetitle'>Bronze:</span> 7.99$/monthly</li>
                <li>2 Email Accounts</li>
                <li>Secure web hosting</li>
                <li>Guaranteed 99.9% Uptime</li>
              </ul>
            </div>
            <div className='small-card'>
              <ul>
                <li><span className='silvertitle'>Silver:</span> 10.99$/monthly</li>
                <li>5 Email Accounts</li>
                <li>Secure web hosting</li>
                <li>Guaranteed 99.9% Uptime</li>
              </ul>
            </div>
            <div className='small-card'>
              <ul>
                <li><span className='goldtitle'>Gold:</span> 13.99$/monthly</li>
                <li>Unlimited Email Accounts</li>
                <li>Secure web hosting</li>
                <li>Guaranteed 99.9% Uptime</li>
                <li>Can host an additional domain</li>
              </ul>
            </div>
            <div className='small-card'>
              <ul>
                <li><span className='setuptitle'>Setup:</span> One-time fee 50$</li>
                <li>Register new domain name</li>
                <li>Register web hosting</li>
                <li>Manage files on hosting server</li>
                <li>Backup files on hosting server</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <p id='supporttext'>
        * All hosting packages include my support.
      </p>


      <div id='portfolio'>
        <div id='portfoliobg'>
          <h2 id='port'>Portfolio</h2>
          <h3 id='porttitle'>
            Previous Work for Clients
          </h3>
          <div className='card-container-one'>
            <div className='large-card'>
              <a href='https://hongkongmassagetherapyclinic.com'><img id='hkmt' src={hkmt} alt="HK Website" /></a>
            </div>
            <div className='large-card'>
              <p>
                Coming Soon!
              </p>
            </div>
            <div className='large-card'>
              <p>
                Coming Soon!
              </p>
            </div>
          </div>
        </div>
      </div>


      <div id='contactme'>
        <div id='contactbg'>
          
          <div id='ContactForm'>
            <h2 id='contactheader'>Contact Me</h2>
            <form ref={form} id='contact-form' onSubmit={sendEmail}>
              <label for="firstname">First Name</label>
              <input type="text" id="firstname" name="firstname" placeholder="First name" required />

              <label for="lastname">Last Name</label>
              <input type="text" id="lastname" name="lastname" placeholder="Last name" />

              <label for="email">E-mail</label>
              <input type="email" id="email" name="email" placeholder="Email address" required />

              <label for="message">Message</label>
              <textarea id="message" name="message" placeholder="Type a message" required></textarea>

              <input type="submit" id='submitbutton' value="Send" />
            </form>
          </div>
        </div>
      </div>

      <div className='foot'>
        <p>Damon Morgan © 2024</p>
      </div>
    </div>
  );

};
export default App;